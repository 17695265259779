<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2024-07-22 09:09:56
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-08-13 15:56:21
 * @FilePath: \dataview-viewer-test\src\views\todo\index.vue
 * @Description: 
-->
<template>
  <div class="todo-cont">
    <alertDetailPop v-if="isPop" :item="item" @close-pop="isPop = false" @changeItemDetail="changeItemDetail"></alertDetailPop>
    <alertNotifyPop v-if="isAlertPop" :alertItemData="alertItemData" @close-pop="isAlertPop = false"></alertNotifyPop>
    <systemNoticePop v-if="isSysPop" @close-pop="isSysPop = false" :SysItemData="SysItemData" ></systemNoticePop>
    <systemDetailNotifyPop v-if="isSysDetailPop" :item="SysDetailItemData"  @close-pop="isSysDetailPop = false"></systemDetailNotifyPop>
    <div class="left-cont">
      <LeftContent />
    </div>
    <div class="right-cont">
      <shortcutMenu></shortcutMenu>
      <alertNotifications :key="refreshAlertEl" @switchAlertPop="switchAlertPop" :isUpdate="alertIsUpdate"></alertNotifications>
      <systemNotice @switchSysPop="switchSysPop" :isUpdate="systemIsUpdate"></systemNotice>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import systemDetailNotifyPop from "./components/rightCont/systemNotice/systemDetailNotifyPop.vue";
import LeftContent from "@/views/todo/components/leftContent/LeftContent.vue";
import systemNoticePop from './components/rightCont/systemNotice/systemNoticePop.vue';
import alertDetailPop from './components/rightCont/alertNotify/alertDetailPop.vue';
import shortcutMenu from './components/rightCont/shortCutMenu/shortcutMenu.vue';
import alertNotifications from './components/rightCont/alertNotify/alertNotifications.vue';
import systemNotice from './components/rightCont/systemNotice/systemNotice.vue';
import alertNotifyPop from './components/rightCont/alertNotify/alertNotifyPop.vue';
import { dataInterface } from '@/apis/data';
import { ref,provide } from 'vue';
// 预警详情弹窗
const isPop = ref(false);
const item = ref({});
//预警弹窗
const isAlertPop = ref(false);
const alertItemData = ref({});
//系统公告弹窗
const isSysPop = ref(false);
const SysItemData = ref({});
const isSysDetailPop = ref(false)
const SysDetailItemData = ref({})
const alertIsUpdate = ref(false)
const systemIsUpdate = ref(false)
const refreshAlertEl = ref('')
const changeItemDetail = () =>{
    dataInterface({},'api/graph/1288').then(res => {
        if (res.data.code === 200) {
            res.data.data.forEach(el => {
                if(item.value.id===el.id){
                    item.value=el
                    refreshAlertEl.value=Date.now()
                }
            })
        }

    })
}
const switchAlertPop = (data, type) => {
  if(type === 'all'){
    alertItemData.value = data;
    isAlertPop.value = true;
  } else {
    item.value = data;
    isPop.value = true;
  }
};

const switchSysPop = (data, type) => {
  if(type === 'all'){
    SysItemData.value = data;
    isSysPop.value = true;
  } else {
    SysDetailItemData.value = data;
    isSysDetailPop.value = true;
  }
}

const toUpdate=(p1,p2)=>{
  if(p1){
    alertIsUpdate.value=!alertIsUpdate.value
  }else if(p2){
    systemIsUpdate.value=!systemIsUpdate.value
  }
}
provide("toUpdate",toUpdate)
</script>

<style lang="less" scoped>
.todo-cont {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: rgb(242, 244, 248);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  .left-cont {
    height: 100%;
    border-radius: 12px;
    flex: 1;
    overflow: hidden;
  }

  .right-cont {
    width: 400px;
    height: 100%;
    margin-left: 16px;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}
</style>

<!--
 * @Author: qinmengyuan
 * @Date: 2024-07-22 15:54:10
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-11-05 16:49:29
 * @FilePath: \dataview-viewer-test\src\views\todo\components\leftContent\LeftContent.vue
-->
<template>
  <div class="container">
    <div class="header">
      <img
        src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/4328d8c3b025808ff865b2fbd244433f.png"
        alt=""
      />
      <div class="title">待办事项</div>
    </div>
    <div class="status">
      <div
        :class="{ item: true, active: activeStatus === item.status }"
        v-for="item in STATUS_LIST"
        :key="item.status"
        :style="{ background: item.background }"
        @click="handleTab(item.status)"
      >
        <div class="icon" :style="{ background: item.icon_background }">
          <img :src="item.icon" alt="" />
        </div>
        <div class="desc">
          <div class="num-cont">
            <span class="num">
              {{
                item.unread ? statusNum[item.unread] : statusNum[item.status]
              }}</span
            >
            <span class="unread" v-if="item.unread"
              ><span>/</span>{{ statusNum[item.status] }}</span
            >
          </div>
          <span> {{ item.name }} <span class="suffix">(项)</span></span>
        </div>
      </div>
    </div>
    <div class="filter-bar">
      <div class="type-list">
        <div
          @click="handleType(item.value)"
          :class="{ 'type-item': true, active: type === item.value }"
          v-for="item in typeList"
          :key="item.value"
        >
          {{ item.name }}
        </div>
      </div>
      <el-input
        size="medium"
        v-model="searchKey"
        style="width: 280px"
        placeholder="搜索关键词"
        suffix-icon="el-icon-search"
      />
      <Popover trigger="click" popper-class="filter-pop">
        <el-button slot="reference"
          ><i class="iconfont iconshaixuan4"></i
          ><span class="num">{{
            filterLength ? `+` + filterLength : ''
          }}</span></el-button
        >
        <FilterModal @ok="getForm" :type="type" />
      </Popover>
    </div>

    <div class="table" v-loading="isLoading" v-if="type === 'list'">
      <el-table
        :data="tableData"
        header-row-class-name="table-header"
        style="width: 100%"
        height="100%"
        stripe
        @row-click="handleRow"
        @sort-change="handleSort"
      >
        <el-table-column
          v-for="column in base_columns.concat(
            type_columns.find((item) => item.type === activeStatus).columns
          )"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :width="column.width"
          :sortable="column.sortable"
        >
          <template slot-scope="scope">
            <div v-if="column.prop === 'user_name'">
              <div
                :class="{ 'user-avatar': true, null: !scope.row.user_name }"
                v-if="
                  !avatar_null.includes(+scope.row.status) ||
                  activeStatus === 'CCToMeTotal'
                "
              >
                <img
                  class="avatar"
                  :src="
                    scope.row.user_avatar ||
                    'https://dwh_api.bimcc.net/uploads/202301/17/1673943165_C8E5tNmVgm.png'
                  "
                  alt=""
                />
                {{ scope.row.user_name || '-' }}
              </div>
              <div v-else class="null">&nbsp;-</div>
            </div>
            <div v-if="column.prop === 'status'">
              <div
                class="state"
                :style="{
                  backgroundColor: STATE_LIST.find(
                    (s) => s.value === +scope.row.status
                  )?.color,
                }"
              >
                {{
                  STATE_LIST.find((item) => item.value === +scope.row.status)
                    ?.label
                }}
              </div>
            </div>
            <div v-if="column.prop === 'endDate'">
              <span :class="deadLine(scope.row, column.prop).class">
                {{ deadLine(scope.row, column.prop).text }}
              </span>
            </div>
            <div v-if="column.prop === 'expiration_date'">
              <span :class="deadLine(scope.row, column.prop).class">
                {{ deadLine(scope.row, column.prop).text }}
              </span>
            </div>
            <div v-if="column.prop === 'created_at'">
              {{ scope.row.start_date + ' ' + scope.row.satrtTime }}
            </div>
            <div v-if="column.prop === 'read'">
              <div :class="{ read: true, unread: scope.row.read === 0 }">
                <div class="circle" v-if="scope.row.read === 0"></div>
                {{ scope.row.read ? '已读' : '未读' }}
              </div>
            </div>
            <div v-if="!custom_columns_props.includes(column.prop)">
              <span :class="{ null: !scope.row[column.prop] }">{{
                scope.row[column.prop] || ' -'
              }}</span>
            </div>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <i class="iconfont iconzanwudaiban"></i>
          <span>暂无待办</span>
        </div>
      </el-table>
    </div>
    <div class="gantt-cont" v-else>
      <Calendar
        ref="calendarRef"
        :dateType="type"
        :todoType="
          STATUS_LIST.find((item) => item.status === activeStatus)?.type || ''
        "
        :search="search"
        :keyWork="searchKey"
        @show-task-detail="handleRow"
      />
    </div>
    <Pagination
      class="pagination"
      v-if="type === 'list' && tableData.length"
      :current-page="pagination.page"
      :page-size="pagination.size"
      :page-sizes="[15, 30, 50, 100]"
      layout="slot, sizes, prev, pager, next"
      :total="pagination.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
      <div class="total">共{{ pagination.total }}条数据</div>
      <div class="wrap"></div>
    </Pagination>
    <FlowFinishPop
      :dialogVisible="finishVisible"
      @close="handleFinishClose"
      :detail="detail"
      @open-next="handleNextRow"
    />
  </div>
</template>

<script setup>
/* eslint-disable */
import {
  onMounted,
  reactive,
  ref,
  watch,
  computed,
  onBeforeUnmount,
} from 'vue';
import { STATUS_LIST, STATE_LIST } from '../../constant.js';
import FilterModal from './FilterModal.vue';
import { format } from 'date-fns';
import { Message, Popover } from 'element-ui';
import { getHomeTODOList, getTODOStatusNum, getTODORead } from '@/apis/home.js';
import { Pagination } from 'element-ui';
import Calendar from '@/views/todo/components/calendar/index.vue';
import FlowFinishPop from './FlowFinishPop.vue';
import eventBus from '@/plugins/eventBus';
const custom_columns_props = [
  'read',
  'endDate',
  'status',
  'user_name',
  'created_at',
  'expiration_date',
];
const activeStatus = ref('pending');
const calendarRef = ref(null);
const detail = ref({});
const type = ref('list');
const searchKey = ref('');
const statusNum = ref({});
const isLoading = ref(false);
const finishVisible = ref(false);
const search = ref({});
const filterLength = ref(0);
const openForm = ref(false);
const pagination = reactive({
  page: 1,
  size: 15,
  total: 0,
});
const tableData = ref([]);
const orderProp = ref({});
// 用户头像不展示的流程状态
const avatar_null = [2, 5, 6, 7];
const typeList = [
  {
    name: '列表',
    value: 'list',
  },
  {
    name: '日',
    value: 'day',
  },
  {
    name: '周',
    value: 'week',
  },
  {
    name: '月',
    value: 'month',
  },
];
const base_columns = computed(() => [
  {
    prop: 'index',
    label: '序号',
    fixed: true,
    width: 58,
  },
  {
    prop: 'flow_approval_number',
    label: '审批编号',
  },
  {
    prop: 'archi_name',
    label: '所属项目',
  },
  {
    prop: 'object_name',
    label: '功能名称',
  },
  {
    prop: 'title',
    label: '通知内容',
    width: 366,
  },
  ...(activeStatus.value !== 'processedHandled'
    ? [
        {
          prop: 'user_name',
          label:
            activeStatus.value === 'pending'
              ? '上一步处理人'
              : activeStatus.value === 'CCToMeTotal'
              ? '抄送人'
              : '当前处理人',
          width: 145,
        },
      ]
    : []),
  {
    prop: 'status',
    label: '流程状态',
    width: 100,
  },
]);

const pending_columns = [
  {
    prop: 'expiration_date',
    label: '截止日期',
    sortable: 'custom',
    width: 120,
  },
  {
    prop: 'endDate',
    label: '逾期情况',
    sortable: 'custom',
  },
];
const copy_columns = [
  {
    prop: 'created_at',
    label: '抄送日期',
    sortable: 'custom',
    width: 140,
  },
  {
    prop: 'read',
    label: '阅读状态',
  },
];
const deal_columns = [
  {
    prop: 'created_at',
    label: '处理日期',
    sortable: 'custom',
    width: 140,
  },
];
const type_columns = [
  {
    type: 'pending',
    columns: pending_columns,
  },
  {
    type: 'initiatedByMe',
    columns: pending_columns,
  },
  {
    type: 'CCToMeTotal',
    columns: copy_columns,
  },
  {
    type: 'processedHandled',
    columns: deal_columns,
  },
];
/**
 * @name:
 * @description: 列表row弹窗
 * @msg:
 * @param {*} item
 * @return {*}
 */
const handleRow = (item) => {
  if (openForm.value) return;
  detail.value = item;
  const tagType = {
    pending: 'check',
    initiatedByMe: 'mine',
    CCToMeTotal: 'copy',
    processedHandled: 'finish',
  };
  let data = {
    event: 'showDetailModal',
    form_uuid: item.form_id,
    flow_id: item.flow_id,
    data_id: item.id,
    module_name: item.module_name,
    object_name: item.object_name,
    archi_type: item.archi_type,
    tag: tagType[activeStatus.value],
    ...(activeStatus.value === 'CCToMeTotal' &&
      +item.read === 0 && {
        show_footer: true,
        is_read: true,
        msg_id: item.msg_id,
      }),
  };
  if (item.task_engine_id) {
    let {
      form_type,
      func_form,
      task_recover_id,
      task_engine_id,
      task_log_id,
      object_uuid,
    } = item;
    const taskConfig = {
      form_type,
      func_form,
      task_recover_id,
      task_engine_id,
      task_log_id,
      object_uuid,
    };
    data.taskConfig = taskConfig;
  }
  openForm.value = true;
  window.parent.postMessage(data, '*');
  return;
};
eventBus.$on('updateTodoList', () => {
  if (type.value === 'list') {
    getHomeList();
    getStatusNum();
  } else {
    calendarRef.value?.refreshFunction();
  }
});
/**
 * @name:
 * @description: 弹出下一个流程弹窗
 * @msg:
 * @return {*}
 */
const handleNextRow = () => {
  if (type.value === 'list') {
    if (detail.value?.index === tableData.value.length) {
      getHomeList(1, 'next-page');
    } else {
      detail.value = tableData.value[detail.value?.index];
      handleRow(detail.value);
    }
  } else {
    calendarRef.value?.handleNext();
  }
};
/**
 * @name:
 * @description: 刷新状态数量
 * @msg:
 * @return {*}
 */
const getStatusNum = (message) => {
  getTODOStatusNum().then((res) => {
    if (res.data.code === 200) {
      statusNum.value = res.data.data;
      if (message) {
        if (res.data.data[activeStatus.value] === 0) {
          Message.info('您已完成所有审核！');
          getHomeList();
        } else {
          finishVisible.value = true;
        }
      }
    }
  });
};
/**
 * @name:
 * @description: 点击状态tab
 * @msg:
 * @param {*} val
 * @return {*}
 */
const handleTab = (val) => {
  activeStatus.value = val;
};
/**
 * @name:
 * @description: 点击列表分类
 * @msg:
 * @param {*} val
 * @return {*}
 */
const handleType = (val) => {
  type.value = val;
};
/**
 * @name:
 * @description: 时间格式化
 * @msg:
 * @param {*} time
 * @return {*}
 */
const finishedTimeFormat = (time) => {
  let nowDateObj = new Date();
  const targetDate = new Date(time);
  const ny = nowDateObj.getFullYear();
  const ty = targetDate.getFullYear();
  const nm = nowDateObj.getMonth();
  const tm = targetDate.getMonth();
  const nd = nowDateObj.getDate();
  const td = targetDate.getDate();
  if (ny === ty) {
    if (nm === tm) {
      if (nd === td) {
        return `今天${format(targetDate, 'HH:mm')}`;
      } else if (nd - td === 1) {
        return `昨天${format(targetDate, 'HH:mm')}`;
      } else {
        return `${format(targetDate, 'MM-dd HH:mm')}`;
      }
    } else {
      return `${format(targetDate, 'MM-dd HH:mm')}`;
    }
  } else {
    return `${format(targetDate, 'yyyy-MM-dd HH:mm')}`;
  }
};
const timeFormat = (time) => {
  let nowDateObj = new Date();
  const targetDate = new Date(time);
  const ny = nowDateObj.getFullYear();
  const ty = targetDate.getFullYear();
  const nm = nowDateObj.getMonth();
  const tm = targetDate.getMonth();
  const nd = nowDateObj.getDate();
  const td = targetDate.getDate();
  if (ny === ty) {
    if (nm === tm) {
      if (nd === td) {
        return `今天${format(targetDate, 'HH:mm')}`;
      } else if (td - nd === 1) {
        return `明天${format(targetDate, 'HH:mm')}`;
      } else {
        return `${format(targetDate, 'MM-dd HH:mm')}`;
      }
    } else {
      return `${format(targetDate, 'MM-dd HH:mm')}`;
    }
  } else {
    return `${format(targetDate, 'yyyy-MM-dd HH:mm')}`;
  }
};
/**
 * @name:
 * @description: 时间格式化
 * @msg:
 * @param {*} item
 * @return {*}
 */
const deadLine = (item, prop) => {
  const deadLine = item.endDate;
  let date1_s = new Date(deadLine?.replace(/-/g, '/'));
  const nowTime = new Date();
  let dateDiff = nowTime.getTime() - date1_s.getTime();
  let date = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
  const finishStatus = [2, 5, 6, 7];
  if (prop === 'endDate') {
    if (nowTime.getTime() > date1_s.getTime() && date) {
      if (finishStatus.includes(+item.status)) {
        return { class: 'null', text: ' -' };
      }
      return { text: '逾期' + date + '天', class: 'red-time' };
    } else {
      return { class: 'null', text: ' -' };
    }
  } else {
    if (nowTime.getTime() > date1_s.getTime() && date) {
      return {
        text: timeFormat(item.endDate),
        class: item.endDate ? 'normal' : 'null',
      };
    } else {
      return { class: 'normal', text: timeFormat(item.endDate) };
    }
  }
  // if ([2, 5].includes(item.status)) {
  //   return {
  //     text: finishedTimeFormat(deadLine),
  //     class: "gray-time",
  //   };
  // }
};
/**
 * @name:
 * @description: 分页改变
 * @msg:
 * @param {*} size
 * @return {*}
 */
const handleSizeChange = (size) => {
  pagination.size = size;
};
const handleCurrentChange = (page) => {
  pagination.page = page;
};
/**
 * @name:
 * @description: 获取列表
 * @msg:
 * @return {*}
 */
const getHomeList = (page, event) => {
  isLoading.value = true;
  getHomeTODOList({
    page: page || pagination.page,
    size: pagination.size,
    todo_type: STATUS_LIST.find((item) => item.status === activeStatus.value)
      ?.type,
    ...(searchKey.value && { key_word: searchKey.value }),
    ...search.value,
    ...orderProp.value,
  })
    .then((res) => {
      if (res.data.code === 200) {
        tableData.value = res.data.data.data.map((item, index) => ({
          ...item,
          index: index + 1,
        }));
        pagination.total = res.data.data.total;
        if (event) {
          if (res.data.data.total) {
            detail.value = tableData.value[0];
            handleRow(detail.value);
          } else {
            Message.info('您已完成所有审核！');
          }
        }
      }
    })
    .finally(() => {
      isLoading.value = false;
    });
};
/**
 * @name:
 * @description: 获取筛选条件
 * @msg:
 * @param {*} form
 * @return {*}
 */
const getForm = (form) => {
  const {
    flow_approval_number,
    data_archi_name,
    object_name,
    title,
    user_name,
    status,
    start_date,
    end_date,
  } = form;
  search.value = {
    ...(flow_approval_number && { flow_approval_number }),
    ...(data_archi_name && { data_archi_name }),
    ...(object_name && { object_name }),
    ...(title && { title }),
    ...(user_name && { user_name }),
    ...(status + '' && { status }),
    ...(start_date && { start_date }),
    ...(end_date && { end_date }),
  };
  filterLength.value = Object.keys(search.value).length;
};
/**
 * @name:
 * @description: 表格排序
 * @msg:
 * @param {*} column
 * @param {*} prop
 * @param {*} order
 * @return {*}
 */
const handleSort = ({ column, prop, order }) => {
  let filed;
  switch (prop) {
    case 'endDate':
    case 'expiration_date':
      filed = 'expiration_date';
      break;
    case 'created_at':
      filed = 'created_date';
      break;
    default:
      filed = 'expiration_date';
      break;
  }
  if (order === 'ascending') {
    orderProp.value = { order_field: filed, order_direction: 1 };
  } else {
    orderProp.value = { order_field: filed, order_direction: 2 };
  }
};
/**
 * @name:
 * @description: 关闭完成弹窗
 * @msg:
 * @param {*} refresh
 * @return {*}
 */
const handleFinishClose = (refresh) => {
  finishVisible.value = false;
  if (refresh) {
    getHomeList();
    getStatusNum();
  }
};
watch(
  () => [
    pagination.size,
    pagination.page,
    activeStatus.value,
    search.value,
    searchKey.value,
    orderProp.value,
    type.value,
  ],
  () => {
    if (type.value === 'list') {
      getHomeList();
    }
  }
);
const handleFlow = (e) => {
  if (e.data.event === 'open-next-flow') {
    if (activeStatus.value === 'CCToMeTotal') {
      getTODORead({ msg_id: detail.value?.msg_id }).then((res) => {
        if (res.data.code === 200) {
          getHomeList();
          getStatusNum();
        }
      });
    } else {
      getStatusNum('open');
    }
  }
  if (e.data.event === 'refresh-todo-list') {
    getHomeList();
    getStatusNum();
  }
  if (e.data.event === 'close-todo-list-form') {
    openForm.value = false;
  }
};
onMounted(() => {
  getHomeList();
  getStatusNum();
  window.addEventListener('message', handleFlow);
});
onBeforeUnmount(() => {
  window.removeEventListener('message', handleFlow);
});
</script>
<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;
  .header {
    padding: 10px 20px;
    align-items: center;
    display: flex;
    img {
      width: 36px;
    }
    .title {
      color: #181b22;
      font-size: 16px;
      font-style: normal;
      font-weight: 450;
      line-height: 24px;
    }
  }
  .item:nth-of-type(1) {
    &:hover {
      background: linear-gradient(
        94deg,
        rgba(242, 247, 255, 0.9) 0%,
        rgba(231, 240, 254, 0.9) 50%,
        rgba(242, 247, 255, 0.9) 100%
      ) !important;
    }
  }
  .item:nth-of-type(2) {
    &:hover {
      background: linear-gradient(
        94deg,
        rgba(233, 251, 239, 0.9) 0%,
        rgba(223, 247, 229, 0.9) 50%,
        rgba(233, 251, 239, 0.9) 100%
      ) !important;
    }
  }
  .item:nth-of-type(3) {
    &:hover {
      background: linear-gradient(
        94deg,
        rgba(254, 246, 223, 0.9) 0%,
        rgba(254, 246, 223, 0.9) 50%,
        rgba(254, 246, 223, 0.9) 100%
      ) !important;
    }
  }
  .item:nth-of-type(4) {
    &:hover {
      background: linear-gradient(
        94deg,
        rgba(244, 246, 249, 0.9) 0%,
        rgba(240, 241, 244, 0.9) 50%,
        rgba(244, 246, 249, 0.9) 100%
      ) !important;
    }
  }
  .status {
    padding: 20px;
    display: flex;
    border-top: 1px solid #f0f1f4;
    gap: 16px;
    overflow-x: auto;
    .item {
      flex: 1;
      height: 80px;
      padding: 0px 26px;
      border-radius: 10px;
      border: 1px solid rgba(51, 51, 51, 0.01);
      display: flex;
      gap: 20px;
      align-items: center;
      position: relative;
      .icon {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .desc {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #707786;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        white-space: nowrap;
        .num-cont {
          display: flex;
          gap: 6px;
          align-items: baseline;
        }
        .num {
          color: #181b22;
          font-size: 24px;
          font-weight: 450;
          line-height: 32px;
          letter-spacing: 0.5px;
          opacity: 0.9;
        }
        .unread {
          color: #a4acbd;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          letter-spacing: 0.5px;
          display: flex;
          gap: 8px;
        }
        .suffix {
          color: var(--text-on-surface-placeholder, #a4acbd);
          font-size: 14px;
          font-style: normal;
          font-weight: 330;
          line-height: 22px; /* 157.143% */
          letter-spacing: 2px;
        }
      }
    }

    .active {
      background: #fff !important;
      &:hover {
        background: #fff !important;
      }
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: var(--Radio-10, 10px);
        border: 2px solid var(--themeColor);
        box-shadow: 0px 0px 4px 0px rgba(var(--themeColorRgb), 0.4);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .filter-bar {
    user-select: none;
    padding: var(--spacing-none, 0px) var(--spacing-10, 20px)
      var(--spacing-10, 20px) var(--spacing-10, 20px);
    display: flex;
    gap: 16px;
    flex-shrink: 0;
    align-items: center;
    .type-list {
      display: flex;
      height: 30px;
      padding: 3px;
      align-items: center;
      gap: 3px;
      border-radius: var(--Radio-8, 8px);
      background: var(--overall-surface-variant, #f2f5fa);
      .type-item {
        min-width: 28px;
        padding: 4px 16px;
        line-height: 22px;
        text-align: center;
      }
      .active {
        color: var(--themeColor);
        border-radius: var(--Radio-6, 6px);
        background: var(--overall-surface-default, #fff);
        box-shadow: 0px 1px 3px 0px rgba(25, 27, 35, 0.04),
          0px 0px 0px 0px rgba(255, 255, 255, 0.01) inset,
          0px 2px 16px 0px rgba(25, 27, 35, 0.08);
      }
    }
    .iconshaixuan4 {
      color: #8991a2;
    }
    ::v-deep {
      .el-input__inner,
      .el-button {
        border-radius: 8px;
        &:hover {
          background: #fff;
          border: 1px solid #dcdfe6;
        }
        &:focus {
          border: 1px solid var(--themeColor);
          background: #fff;
          box-shadow: 0px 0px 0px 0px var(--themeColor) inset,
            0px 0px 0px 2px rgba(var(--themeColorRgb), 0.2);
        }
        .num {
          color: var(--themeColor);
          font-size: 14px;
          font-style: normal;
        }
      }
    }
  }
  .table {
    padding: 0 20px;
    flex: 1;
    display: flex;
    overflow: hidden;
    ::v-deep(.table-header) {
      .el-table__cell {
        background-color: #f2f5fa;
        padding: 0;
        height: 36px;
        .cell {
          white-space: nowrap;
          color: #707786;
          font-size: 13px;
          font-style: normal;
        }
      }
    }
    ::v-deep(.el-table__body-wrapper) {
      height: calc(100% - 36px) !important;
      .el-table__body td.el-table__cell {
        border: none;
        font-size: 14px;
        color: var(--text-on-surface-primary, #181b22);
        font-size: 14px;
        font-style: normal;
        padding: 10px 0;
        .cell {
          line-height: 22px !important;
          span {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: auto-phrase;
          }
        }
      }
      .el-table__row--striped td.el-table__cell {
        background: rgba(242, 245, 250, 0.5);
      }
    }
    .user-avatar {
      display: flex;
      align-items: center;
      gap: 8px;
      .avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
    .null {
      color: #a4acbd;
    }

    .red-time {
      color: #ed474a;
      font-size: 14px;
      opacity: 0.9;
    }
    .state {
      display: flex;
      height: 28px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      width: fit-content;
      color: var(--semantic-positive-black-secondary, #596058);
      font-size: 13px;
      font-style: normal;
      font-weight: 380;
      line-height: 20px; /* 153.846% */
    }
    .read {
      color: #a4acbd;
    }
    .unread {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #707786;
      .circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%
          ),
          var(--semantic-error-background-variant, #fc5558);
      }
    }
    .empty {
      display: flex;
      flex-direction: column;
      .iconfont {
        font-size: 42px;
        line-height: 48px;
        color: #a4acbd;
      }
      span {
        color: var(--text-on-surface-placeholder, #a4acbd);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }
  .pagination {
    padding: 16px;
  }
  .gantt-cont {
    flex: 1;
    overflow: hidden;
    padding: 0 20px;
  }
  .el-pagination {
    display: flex;
    align-items: center;
    ::v-deep(.el-pager) {
      height: 24px;
      .number {
        color: #424751;
        font-size: 12px;
        font-weight: 400;
        min-width: 24px !important;
        height: 24px;
        line-height: 24px;
      }
      .active {
        color: var(--themeColor) !important;
        border-radius: var(--radius-2, 4px) !important;
        border: 1px solid var(--themeColor) !important;
        background: var(
          ---button-brand-secondary-background,
          rgba(255, 255, 255, 0.01)
        ) !important;
      }
    }
    .total {
      color: #707786;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    .wrap {
      flex: 1;
    }
  }
}
</style>
<style>
.filter-pop {
  padding: 12px 8px 20px 20px;
  border-radius: 8px;
}
</style>
